import { createRoot } from "react-dom/client";
import Moment from "react-moment";
import { createInertiaApp } from "@inertiajs/react";
import * as Sentry from "@sentry/react";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import moment from "moment-timezone";

import "./bootstrap";
import "moment/locale/nl";

import ErrorComponent from "./components/common/ErrorComponent";

moment.tz.setDefault("Europe/Amsterdam");
Moment.globalLocale = "nl";

const appName =
  window.document.getElementsByTagName("title")[0]?.innerText || "Laravel";

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) =>
    resolvePageComponent(
      `./pages/${name}.tsx`,
      import.meta.glob("./pages/**/*.tsx"),
    ),
  setup({ el, App, props }) {
    const root = createRoot(el); // createRoot(container!) if you use TypeScript

    // if (import.meta.env.PROD) {
    //   Sentry.init({
    //     dsn: "https://9535feee95eed905f8198f3397d54cc5@o4505868015239168.ingest.sentry.io/4505868019105792",
    //     integrations: [
    //       new Sentry.BrowserTracing({
    //         // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    //         tracePropagationTargets: [
    //           "localhost",
    //           "wazoe.app",
    //           "*.wazoe.app",
    //           "*.localhost",
    //         ],
    //       }),
    //     ],
    //     // Performance Monitoring
    //     tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    //     // Session Replay
    //     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    //     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    //   });
    // }

    return root.render(
      <Sentry.ErrorBoundary fallback={ErrorComponent}>
        <App {...props} />
      </Sentry.ErrorBoundary>,
    );
  },
  progress: {
    color: "#FAA331",
  },
});
