import { ShieldBanIcon } from "lucide-react";

const ErrorComponent = () => (
  <div className="flex h-[calc(100vh-80px)] w-full items-center justify-center bg-white p-5">
    <div className="text-center">
      <div className="inline-flex rounded-full bg-red-100 p-4">
        <div className="rounded-full bg-red-200 p-4 text-red-600 ">
          <ShieldBanIcon />
        </div>
      </div>

      <p className="mt-5 text-slate-600 lg:text-lg">
        Er is wat fout gegaan, probeer het later nog eens.
      </p>
    </div>
  </div>
);

export default ErrorComponent;
